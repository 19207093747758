import { CSSProperties } from 'react';
import { InputProps } from './components/Input';
import { DatePickerRangeProps } from './components/DatePickerRange';

export interface BaseInput {
  errors?: string | string[];
  id: string;
  label?: string;
}

export type OnChangeDatesProp = Parameters<
  DatePickerRangeProps['onDatesChange']
>[0];

export type { FocusedInputShape, RenderMonthProps } from 'react-dates';

export interface AutocompleteProps
  extends Pick<
    InputProps,
    'id' | 'label' | 'value' | 'onChange' | 'placeholder'
  > {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  maxDropdownWidth?: CSSProperties['maxWidth'];
  showErrors?: boolean;
}
