import { datadogInit } from '@shiftsmartinc/remix-telemetry';
import { hydrateRoot } from 'react-dom/client';
import { initClientAmplitude } from '@shiftsmartinc/remix-utils';
import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { AMPLITUDE_KEY, APP_ENV, APP_NAME, APP_VERSION } from '~/global/config';

/**
 * Initialize Datadog as early as possible, we may also want to initialize
 * other external client side services here
 */
datadogInit({
  APP_ENV,
  APP_NAME,
  APP_VERSION,
  applicationId: 'd187d25e-7b08-4433-84b1-75a0ba0b2c8e',
  clientToken: 'pubf7ce200376081678c8c4f7c827b54d99',
});

/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});

initClientAmplitude(AMPLITUDE_KEY);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/worker.js');
  });

  /**
   * Prevents the default mini-infobar or install dialog from appearing on
   * mobile, storing the original event so we can use it later in the users
   * journey.
   */
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    global.deferredPrompt = e;
  });
}
