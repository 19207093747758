import { IBaseItem } from './_base';

export enum IssueReasonsPickList {
  MANAGER_REQUEST = 'Manager request',
  OUT_OF_STOCK = 'Out of stock',
  PRODUCT_DAMAGED_OR_EXPIRED = 'Product damaged or expired',
  SANDWICH_NOT_THAWED = 'Sandwich not thawed',
}

export enum IssueReasonsScannedItems {
  EQUIPMENT_ISSUE = 'Equipment issue',
  MANAGER_REQUEST = 'Manager request',
  NOT_FOUND_IN_BIN = 'Not found in bin',
  PRODUCT_DAMAGED_OR_EXPIRED = 'Product damaged or expired',
  RAN_OUT_OF_TIME = 'Ran out of time',
  STORE_ALREADY_COOKED = 'Store already cooked',
}

export enum IssueReasonsOverProductionPickList {
  MANAGER_REQUEST = 'Manager request',
  PRODUCT_SUBSTITUTION = 'Product substitution',
}

export enum IssueReasonsOverScannedItems {
  CUSTOMER_REQUEST = 'Customer request',
  EXTRA_PRODUCT_IN_BIN = 'Extra product in bin',
  MANAGER_REQUEST = 'Manager request',
  PRODUCT_SUBSTITUTION = 'Product substitution',
}

export enum IssueReasonsOther {
  MANAGER_DIRECTION = 'Manager direction',
  PARTNER_ISSUE = 'Partner issue',
  PRODUCED = 'Produced',
  PRODUCT_EXPIRED = 'Product expired',
  PRODUCT_NOT_USABLE = 'Product not usable',
  SHIFT_TURNAWAY = 'Shift turnaway',
  STORE_ALREADY_PRODUCED = 'Store already produced',
  STORE_REQUEST = 'Store request',
  UNFILLED_SHIFT = 'Unfilled shift',
}

export enum IssueReasonsCustomPickList {
  NOT_IN_POG = 'Not in POG',
}

export const IssueTypeValues = {
  ...IssueReasonsPickList,
  ...IssueReasonsScannedItems,
  ...IssueReasonsOverProductionPickList,
  ...IssueReasonsOverScannedItems,
  ...IssueReasonsOther,
  ...IssueReasonsCustomPickList,
} as const;

export type IssueType = (typeof IssueTypeValues)[keyof typeof IssueTypeValues];

export interface ISkuMetadata {
  label?: string;
  sku_upc?: string;
}

export interface ISku extends IBaseItem {
  code?: string;
  companyId: string;
  group: string;
  imageURL?: string;
  labelURL?: string;
  metaData: ISkuMetadata;
  name: string;
  readableName?: string;
}

export interface IStoreSkuReport {
  report: IStoreSkuReportDetails;
  shiftId: string;
  skuId: ISku['uuid'];
  storeId: string;
  storeName: string;
  taskIdentifier?: string;
  userId: string;
  uuid: string;
}

export interface IStoreSkuReportDetails {
  count: number;
  issueType?: IssueType;
  scanTimestamps?: Date[];
  tlmTaskConfigId?: string;
  trayNumber?: number;
}
