import { Schema, reach } from 'yup';

/**
 * @name stringToValue
 * @description Converts a string to a more specific data type following a yup schema
 */
export const stringToSchemaValue = <S extends Schema>(
  valueString: string,
  key: string,
  schema: S,
) => {
  const schemaField = reach(schema, key).describe();
  const schemaType = schemaField?.type;

  const isNullable = 'nullable' in schemaField && schemaField.nullable;
  const isOptional = 'optional' in schemaField && schemaField.optional;

  if (isNullable && valueString === 'null') return null;
  if (isOptional && valueString === '') return undefined;

  if (schemaType === 'boolean') return valueString === 'true';
  if (schemaType === 'number') return parseFloat(valueString);
  if (schemaType === 'string') return valueString;
  if (schemaType === 'date') return new Date(valueString);

  if (schemaType === 'array') {
    if (!valueString) return [];
    return valueString.split(',');
  }

  throw new Error('Field type not recognized');
};

/**
 * @name stringToValue
 * @description Converts a string to a more specific data type
 */
export const stringToValue = (valueString: string) => {
  if (valueString === 'true') return true;
  if (valueString === 'false') return false;
  if (valueString === 'null') return null;
  if (valueString.match(/^\d+$/)) return parseInt(valueString, 10);

  return valueString;
};
