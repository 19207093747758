import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import {
  IS_BROWSER,
  SHIFTSMART_RUM_ENABLED,
  SHIFTSMART_SAMPLE_RATE,
} from '@shiftsmartinc/remix-utils';
import { useEffect } from 'react';

export interface DatadogConfig {
  APP_ENV: string;
  APP_NAME: string;
  APP_VERSION: string;
  applicationId: string;
  clientToken: string;
}

export interface DatadogUser {
  email?: string | null;
  firstName: string;
  lastName: string;
  uuid: string;
}

/**
 * @description Initialize our Datadog logging and RUM integrations. See the
 * links below for more information around the configuration options.
 */
export const datadogInit = (config: DatadogConfig) => {
  datadogInitLogging(config);
  datadogInitRUM(config);
};

/**
 * @external https://docs.datadoghq.com/logs/log_collection/javascript
 * @external https://docs.datadoghq.com/logs/log_collection/javascript/#configuration
 * @description Send logs to Datadog from web browser pages with the browser logs SDK.
 */
export const datadogInitLogging = (config: DatadogConfig) => {
  const { APP_ENV, APP_NAME, APP_VERSION, clientToken } = config;

  if (!IS_BROWSER) return;

  datadogLogs.init({
    clientToken,
    env: APP_ENV,
    forwardConsoleLogs: 'all',
    forwardErrorsToLogs: true,
    service: `${APP_NAME}.shiftsmart.com`,
    sessionSampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    site: 'datadoghq.com',
    telemetryConfigurationSampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    telemetrySampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    trackLongTasks: true,
    trackResources: true,
    trackSessionAcrossSubdomains: true,
    version: APP_VERSION,
  });
};

/**
 * @external https://docs.datadoghq.com/real_user_monitoring/browser
 * @external https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
 * @external https://docs.datadoghq.com/integrations/rum_react
 * @description Datadog Real User Monitoring (RUM) enables you to visualize
 * and analyze the real-time performance and user journeys of your
 * application’s individual users.
 */
export const datadogInitRUM = (config: DatadogConfig) => {
  const { APP_ENV, APP_NAME, APP_VERSION, applicationId, clientToken } = config;

  if (!IS_BROWSER) return;
  if (!SHIFTSMART_RUM_ENABLED) return;

  datadogRum.init({
    allowedTracingUrls: [
      {
        match: `https://${APP_NAME}.shiftsmart.com`,
        propagatorTypes: ['tracecontext'],
      },
      {
        match: `https://${APP_NAME}-api.shiftsmart.com`,
        propagatorTypes: ['tracecontext'],
      },
      {
        match: 'https://api.shiftsmart.com',
        propagatorTypes: ['tracecontext'],
      },
      {
        match: 'http://localhost:9090',
        propagatorTypes: ['tracecontext'],
      },
    ],
    applicationId,
    clientToken,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['clickmap'],
    env: APP_ENV,
    service: `${APP_NAME}.shiftsmart.com`,
    sessionReplaySampleRate: 25, // Record 25% of the "SHIFTSMART_SAMPLE_RATE %" sessions recorded
    sessionSampleRate: SHIFTSMART_SAMPLE_RATE * 100, // ##% of browsers sessions we track
    site: 'datadoghq.com',
    telemetryConfigurationSampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    telemetrySampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    traceSampleRate: SHIFTSMART_SAMPLE_RATE * 100,
    trackFrustrations: true,
    trackInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    trackSessionAcrossSubdomains: true,
    trackUserInteractions: true,
    version: APP_VERSION,
  });

  // 📹 Start session replay recording
  datadogRum.startSessionReplayRecording();
};

/**
 * @description Deletes the RUM user if exists.
 */
export const datadogClearUser = () => {
  if (!IS_BROWSER) return;

  datadogRum.clearUser();
};

/**
 * @description Sets up the RUM user if in the browser context, and it doesn't exist already.
 */
export const datadogSetUser = <T extends DatadogUser>(user?: T) => {
  if (!IS_BROWSER || !user?.email) return;

  const rumUser = datadogRum.getUser() ?? {};

  if (!rumUser.email || !rumUser.id || !rumUser.name) {
    const datadogUser = {
      email: user.email,
      id: user.uuid,
      name: `${user.firstName} ${user.lastName}`,
    };

    // And set the user context in both the Datadog Logs and RUM
    datadogLogs.setUser(datadogUser);
    datadogRum.setUser(datadogUser);
  }
};

/**
 * @description Sets up the RUM user if in the browser context, and it
 * doesn't already exist.
 */
export const useDatadogUserContext = <T extends DatadogUser>(user?: T) => {
  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle
  useEffect(() => {
    datadogSetUser(user);
  }, [user]);

  // 🔌 Short Circuits
};
