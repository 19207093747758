import { SessionStorage } from '@remix-run/node';
import { HEADER_COOKIE } from '../config/headers';

/**
 * @name getSession
 * @description Returns the flashSession cookie session
 */
export const getSession = async (request: Request, session: SessionStorage) => {
  const header = request.headers.get(HEADER_COOKIE);
  const result = await session.getSession(header);

  return result;
};

// /**
//  * @name setSession
//  * @description Returns the flashSession cookie session
//  */
// export const setSession = async (request: Request, session: SessionStorage) => {
//   const header = request.headers.get(HEADER_COOKIE);
//   const result = await session.getSession(header);

//   return result;
// };

export const setSessionFlash = async (
  request: Request,
  session: SessionStorage,
  key: string,
  value: any,
) => {
  const header = request.headers.get(HEADER_COOKIE);
  const result = await session.getSession(header);

  result.flash(key, value);

  return result;
};
