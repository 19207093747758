import { cssBundleHref } from '@remix-run/css-bundle';
import { ApplicationHTML, IS_DEVELOPMENT } from '@shiftsmartinc/remix-utils';
import { IUser } from '@shiftsmartinc/shiftsmart-types';
import { intersection } from 'lodash';
import { redirect } from '@remix-run/node';
import {
  Outlet,
  useNavigation,
  ShouldRevalidateFunction } from
'@remix-run/react';
import { ToastContainer } from 'react-toastify';
import invariant from 'tiny-invariant';
import radarStyles from 'radar-sdk-js/dist/radar.css';
import rdtStylesheet from 'remix-development-tools/stylesheet.css';
import tooltipStyles from 'react-tooltip/dist/react-tooltip.css';
import type { LinksFunction, LoaderArgs } from '@remix-run/node';
import { typedjson, useTypedLoaderData } from 'remix-typedjson';
import { Loader } from '@shiftsmartinc/shiftsmart-ui';
import { useDatadogUserContext } from '@shiftsmartinc/remix-telemetry';
import { GlobalContext } from './global/components/GlobalContext';
import {
  GetProjectsList,
  getProjectsList } from
'~/routing/projects/actions/getProjectsList';
import { getUser } from '~/global/actions/getUser';
import { getUserSession } from '~/global/actions/getUserSession';
import { GlobalApp } from '~/global/components/GlobalApp';
import { GlobalCommandPalette } from '~/global/components/GlobalCommandPalette';
import { links as manifestLinks } from '~/global/data/manifest';
import { GlobalErrorBoundary } from '~/global/components/GlobalErrorBoundary';
import { GlobalRemixDevTools } from '~/global/components/GlobalRemixDevTools';
import { logger } from '~/global/utils/logger.server';
import { GlobalAppContextShape } from '~/global/context/GlobalAppContext';
import { SignInSchema } from '~/global/data/SignIn.form';
import { CreateProjectSchema } from '~/routing/projects/data/CreateProject.form';
import { redirectLogout } from '~/global/actions/redirectLogout';
import stylesheet from '~/global/styles/index.css';

/**
 * @external https://remix.run/docs/en/route/links
 * @description The links function defines which <link> elements to add to
 * the page when the user visits a route.
 */
export const links: LinksFunction = () => {
  const favicon = '/branding/shiftsmart/favicon.ico';
  const icon = '/branding/shiftsmart/icons/icon-192x192.png';
  const manifest = `/manifest.json`;

  return [
  // Stylesheets
  ...(cssBundleHref ? [{ href: cssBundleHref, rel: 'stylesheet' }] : []),
  ...(rdtStylesheet && IS_DEVELOPMENT ?
  [{ href: rdtStylesheet, rel: 'stylesheet' }] :
  []),

  { href: stylesheet, rel: 'stylesheet', type: 'text/css' },
  { href: radarStyles, rel: 'stylesheet', type: 'text/css' },
  { href: tooltipStyles, rel: 'stylesheet', type: 'text/css' },

  // Links for branding
  ...manifestLinks,
  { href: icon, rel: 'apple-touch-icon' },
  { href: favicon, rel: 'favicon' },
  { href: favicon, rel: 'icon', type: 'image/svg+xml' },
  { href: favicon, rel: 'mask-icon', type: 'image/svg+xml' },
  { href: manifest, rel: 'manifest', type: 'application/manifest+json' },

  // Google Fonts
  {
    href: `https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap`,
    rel: 'stylesheet',
    type: 'text/css'
  }];

};

export interface RootLoaderData {
  accessToken: string;
  env: {
    ALGOLIA_API_KEY: string;
    ALGOLIA_APP_ID: string;
    ALGOLIA_INSTANT_SEARCH_INDEX: string;
    AMPLITUDE_KEY: string;
    API_GRAPHQL: string;
    API_URL: string;
    APP_ENV: string;
    APP_VERSION: string;
    NODE_ENV: string;
    RADAR_PUBLIC_KEY: string;
    SHIFTSMART_RUM_ENABLED: string;
    SHIFTSMART_SAMPLE_RATE: string;
  };
  projects: GetProjectsList[];
  timeZone: string;
  user: IUser;
}

/**
 * @external https://remix.run/docs/en/route/loader
 * @external https://remix.run/docs/en/guides/envvars
 */
export const loader = async (args: LoaderArgs) => {
  const { request } = args;

  const userSession = await getUserSession(request);
  const isAuthRoute = request.url.includes('/auth');
  const isPasswordRoute = request.url.includes('/password');
  const { accessToken, timeZone, userId } = userSession;

  // We don't have a user session to look the user up by
  if (!userId && !isAuthRoute && !isPasswordRoute) {
    return redirect('/auth');
  }

  const user = await getUser(request, userId);

  if (!user.uuid && !isAuthRoute) return redirect('/auth');
  if (user.uuid && isAuthRoute) return redirect('/');

  const userRoles = user?.roles ?? [];
  const isEmailShiftsmart = user.email?.endsWith('@shiftsmart.com');

  const whitelistRoles = ['admin', 'employer', 'super-admin'];
  const isWhiteListed = intersection(userRoles, whitelistRoles).length > 0;

  const blacklistRoles = ['worker'];
  const isBlackListed = intersection(userRoles, blacklistRoles).length > 0;

  // Validate the user has the correct roles and we're not on an auth route
  if (!isAuthRoute && (!isEmailShiftsmart || !isWhiteListed || isBlackListed)) {
    return redirectLogout(request);
  }

  const ALGOLIA_API_KEY = process.env['ALGOLIA_API_KEY'];
  const ALGOLIA_APP_ID = process.env['ALGOLIA_APP_ID'];
  const ALGOLIA_INSTANT_SEARCH_INDEX = process.env['ALGOLIA_INSTANT_SEARCH_INDEX']; // prettier-ignore
  const AMPLITUDE_KEY = process.env['AMPLITUDE_KEY'];
  const API_GRAPHQL = process.env['API_GRAPHQL_EXTERNAL']; // Used by the client (browser)
  const API_URL = process.env['API_URL_EXTERNAL']; // Used by the client (browser)
  const APP_ENV = process.env['APP_ENV'];
  const APP_VERSION = process.env['APP_VERSION'];
  const NODE_ENV = process.env['NODE_ENV'];
  const RADAR_PUBLIC_KEY = process.env['RADAR_PUBLIC_KEY'];
  const SHIFTSMART_RUM_ENABLED = process.env['SHIFTSMART_RUM_ENABLED'];
  const SHIFTSMART_SAMPLE_RATE = process.env['SHIFTSMART_SAMPLE_RATE'];

  invariant(ALGOLIA_API_KEY, `An "Algolia API Key" is required.`);
  invariant(ALGOLIA_APP_ID, `An "Algolia App Id" is required.`);
  invariant(ALGOLIA_INSTANT_SEARCH_INDEX, `An "Algolia Index" is required.`);
  invariant(AMPLITUDE_KEY, `AMPLITUDE_KEY is not set.`);
  invariant(API_GRAPHQL, `API_GRAPHQL is not set.`);
  invariant(API_URL, `API_URL is not set.`);
  invariant(APP_ENV, `APP_ENV is not set.`);
  invariant(APP_VERSION, `APP_VERSION is not set.`);
  invariant(RADAR_PUBLIC_KEY, `A "Radar public key" is required.`);
  invariant(NODE_ENV, `NODE_ENV is not set.`);
  invariant(SHIFTSMART_RUM_ENABLED, `SHIFTSMART_RUM_ENABLED is not set.`);
  invariant(SHIFTSMART_SAMPLE_RATE, `SHIFTSMART_SAMPLE_RATE is not set.`);

  const projects = user.uuid ? await getProjectsList(request) : [];

  const data: RootLoaderData = {
    accessToken,
    // 🚧 These "env" environment variables are exposed to the client
    env: {
      ALGOLIA_API_KEY: '',
      ALGOLIA_APP_ID: '',
      ALGOLIA_INSTANT_SEARCH_INDEX: '',
      AMPLITUDE_KEY: '',
      API_GRAPHQL,
      API_URL,
      APP_ENV,
      APP_VERSION,
      NODE_ENV,
      RADAR_PUBLIC_KEY: '',
      SHIFTSMART_RUM_ENABLED,
      SHIFTSMART_SAMPLE_RATE
    },
    projects,
    timeZone,
    user
  };

  if (user.uuid) {
    logger.debug(`User "${user.uuid}" is logged in.`, { user });

    // We have a valid user so we can safely set these environment variables
    data.env.ALGOLIA_API_KEY = ALGOLIA_API_KEY;
    data.env.ALGOLIA_APP_ID = ALGOLIA_APP_ID;
    data.env.ALGOLIA_INSTANT_SEARCH_INDEX = ALGOLIA_INSTANT_SEARCH_INDEX;
    data.env.AMPLITUDE_KEY = AMPLITUDE_KEY;
    data.env.RADAR_PUBLIC_KEY = RADAR_PUBLIC_KEY;
  }

  return typedjson(data);
};

/**
 * @external https://remix.run/docs/en/main/route/should-revalidate
 * @external https://remix.run/docs/en/main/route/should-revalidate#never-reloading-the-root
 * @description This function lets apps optimize which routes data should be
 * reloaded after actions and for client-side navigations.
 */
export const shouldRevalidate: ShouldRevalidateFunction = (args) => {
  const { formData } = args;
  const formName = formData?.get('formName');

  return (
    formName === CreateProjectSchema.getDefault().formName ||
    formName === SignInSchema.getDefault().formName);

};

/**
 * @external https://remix.run/docs/en/file-conventions/root
 * @description This is the root context + our application
 */
export default function App() {
  // Hooks
  const { env, accessToken, timeZone, user } = useTypedLoaderData();
  const navigation = useNavigation();

  // Setup
  const isLoading = navigation.state === 'loading';
  const roles = user.roles ?? [];
  const globalAppContext: GlobalAppContextShape = {
    accessToken,
    roles,
    timeZone,
    user
  };

  // Handlers

  // Markup

  // Life Cycle
  useDatadogUserContext(user);

  // 🔌 Short Circuits

  return (
    <ApplicationHTML<RootLoaderData['env']> env={env}>
      <GlobalContext globalAppContext={globalAppContext}>
        <GlobalApp>
          <Outlet />
        </GlobalApp>
        <GlobalCommandPalette />
      </GlobalContext>
      <Loader loading={isLoading} />
      <ToastContainer />
      <GlobalRemixDevTools />
    </ApplicationHTML>);

}

/**
 * @name ErrorBoundary
 * @external https://remix.run/docs/en/main/pages/v2#catchboundary-and-errorboundary
 */
export const ErrorBoundary = () => {
  return (
    <ApplicationHTML>
      <GlobalErrorBoundary className="p-4" />
      <ToastContainer />
    </ApplicationHTML>);

};