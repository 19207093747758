import { Types } from '@amplitude/analytics-node';

/**
 * @description We store a global instance of "amplitude" and our "socket"
 * which are added to the "globalThis". We 👀 "have to" use a "var" below to
 * appease the types.
 */
declare global {
  // eslint-disable-next-line no-var
  var amplitude: boolean | undefined;
}

/**
 * @description Amplitude events will vary, however these are the required
 * fields for each event, and the optional success field.
 */
export interface AmplitudeEvent extends Record<string, unknown> {
  /**
   * @description The event name should be in snake_case and include the
   * target and action
   */
  name: string;

  /**
   * @description The route name is used to help distinguish where an event
   * came from. We have page tracking as it's own event, but our components
   * can be used in many places.
   */
  route: string;

  /**
   * @description Used in scenarios where an event can be successful or fail.
   * This field will generally be applicable to the server side code, "actions"
   * and "loaders".
   */
  success?: boolean;
}

export type AmplitudeOptions = Types.EventOptions &
  Required<Pick<Types.EventOptions, 'user_id'>>;
