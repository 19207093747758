import * as amplitude from '@amplitude/analytics-node';
import { IS_DEVELOPMENT } from '../config';
import { AmplitudeOptions, AmplitudeEvent } from '../types';

/**
 * @name initServerAmplitude
 * @external https://www.npmjs.com/package/@amplitude/analytics-node
 * @external https://www.docs.developers.amplitude.com/data/sdks/typescript-node
 * @description Update the "logLevel" below to "Warn" to minimize noise
 * or "Debug" for tons of info
 */
export const initServerAmplitude = async (amplitudeKey: string) => {
  if (global.amplitude) return;
  // eslint-disable-next-line no-console
  if (IS_DEVELOPMENT) console.log(`📊 Amplitude (nodejs)`);

  const { Warn, Error } = amplitude.Types.LogLevel;

  amplitude.init(amplitudeKey, {
    logLevel: IS_DEVELOPMENT ? Warn : Error,
    plan: {
      branch: 'main',
      source: 'nodejs',
      version: '1.0',
    },
  });

  /**
   * Using "global" allows us to persist the initialized instance for use
   * on the next server response.
   */
  global.amplitude = true;
};

/**
 * @name getTrackServerEvent
 * @description Returns an app specific function to track Server Side events in Amplitude
 * @link https://www.docs.developers.amplitude.com/data/sdks/typescript-node/#tracking-an-event
 */
export const getTrackServerEvent =
  <T extends AmplitudeEvent>(appName: string, appVersion: string) =>
  (
    name: T['name'],
    eventProperties: Omit<T, 'name'>,
    options: AmplitudeOptions,
  ) => {
    const eventOptions = {
      app_version: appVersion,
      device_id: appName,
      ...options,
    };

    return amplitude.track(name, eventProperties, eventOptions);
  };
