type PruneObjOptions = {
  /**
   * if true, empty arrays will be deleted from the returned object
   * @default false
   */
  arrays?: boolean;
  /**
   * if true, empty objects will be deleted from the returned object
   * @default true
   */
  objects?: boolean;
};

const pruneObjOptionsDefaults: PruneObjOptions = {
  arrays: false,
  objects: true,
};

type JsonValue = string | number | boolean | null;
type JsonArray = Array<JsonValue | JsonObject>;
interface JsonObject {
  [key: string]: JsonValue | JsonObject | JsonArray | undefined;
}

/**
 * Returns a copy of the given json object but with any empty objects and / or empty arrays deleted.
 * @param json the json object to be pruned
 * @param options specify whether you would like empty objects, empty arrays, or both to be deleted
 *
 * **NOTE**: Ensure typing of input object is already set up to have properties suspected to be
 * removed already set as optional. This function assumes this has already been done and will return
 * with same typing as input json object.
 *
 * @returns a pruned (partial) version of the original json
 */
export const pruneJson = <T extends JsonObject>(
  json: T,
  options: PruneObjOptions = pruneObjOptionsDefaults,
) => {
  options = { ...pruneObjOptionsDefaults, ...options };
  const newJson: T = JSON.parse(JSON.stringify(json), (_, val) => {
    if (
      !Array.isArray(val) &&
      options.objects &&
      typeof val === 'object' &&
      JSON.stringify(val) === '{}'
    ) {
      return undefined;
    } else if (options.arrays && Array.isArray(val) && val.length === 0) {
      return undefined;
    }
    return val;
  });
  return newJson;
};
